import { G2gUserTileComponent } from './../g2g-user-tile/g2g-user-tile.component';
import { PrimeModule } from './../../../modules/primeng.module';
import { Component, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SEARCH_TYPE } from '../../../constants/app.constants';
import { UserService } from '../../../services/user.service';
import { PostService } from '../../../services/post.service';
import { Subject, takeUntil } from 'rxjs';
import { debounce } from 'lodash';
import { G2gUserListComponent } from '../g2g-user-list/g2g-user-list.component';
import { Router } from '@angular/router';
import { VideoListTileComponent } from '../video-list-tile/video-list-tile.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterUserDialogComponent } from '../../widgets/filter-user-dialog/filter-user-dialog.component';
import { FilterVideoDialogComponent } from '../../widgets/filter-video-dialog/filter-video-dialog.component';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-g2g-searchbar',
  standalone: true,
  imports: [
    PrimeModule,
    FormsModule,
    G2gUserListComponent,
    G2gUserTileComponent,
    VideoListTileComponent
  ],
  templateUrl: './g2g-searchbar.component.html',
  styleUrl: './g2g-searchbar.component.scss',
  providers: [DialogService]
})
export class G2gSearchbarComponent implements OnDestroy {
  searchType: number = SEARCH_TYPE.USER;
  checked = false
  public searchQuery: string = '';
  selectedItem: any;
  filteredItems: any[] = []
  items: any[] = [];
  private $unsubscribe = new Subject<void>();

  constructor(
    private _userService: UserService,
    private _postService: PostService,
    private router: Router,
    private _dialogService: DialogService
  ) { }

  onSubmit() { }

  filterItems(event: AutoCompleteCompleteEvent) {
    this.searchItem();
  }

  searchItem = debounce(() => {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    // let query = event.query;
    if (this.searchType == SEARCH_TYPE.USER) {
      this._userService.searchUserByName(this.searchQuery).pipe(takeUntil(this.$unsubscribe))
        .subscribe((res) => {
          this.filteredItems = res || [];
        }, err => this.filteredItems = []);
    } else {
      this._postService.searchVideoByName(this.searchQuery).pipe(takeUntil(this.$unsubscribe))
        .subscribe((res) => {
          this.filteredItems = res || [];
        }, err => this.filteredItems = []);
    }
  }, 2000);

  toggleSearchType() {
    if (this.searchType == SEARCH_TYPE.USER) {
      this.searchType = SEARCH_TYPE.POST
    } else {
      this.searchType = SEARCH_TYPE.USER
    }
  }

  redirect(searchType: number, event: any) {
    this.searchQuery = '';
    if (searchType == SEARCH_TYPE.USER) {
      this.router.navigateByUrl(`user-profile/${event?.value?.id}`);
    } else {
      this.router.navigateByUrl(`watch/${event?.value?.id}`);
    }
  }

  openFilterDialog() {
    let ref!: DynamicDialogRef;
    if (this.searchType == SEARCH_TYPE.USER) {
      ref = this._dialogService.open(FilterUserDialogComponent, {
        header: `Filter & Search Users`,
        width: '480px',
        data: {}
      })
    } else {
      ref = this._dialogService.open(FilterVideoDialogComponent, {
        header: `Filter & Search Posts`,
        width: '480px',
        baseZIndex: 100,
        data: {}
      })
    }

    ref.onClose.subscribe((data) => {
      if (data) {
        this.router.navigateByUrl('/search', { state: { data } });
      }
    });
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

}
