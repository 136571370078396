import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { ProfileService } from '../../../services/profile.service ';
import { ICountry } from '../filter-user-dialog/filter-user-dialog.component';
import { ButtonModule } from 'primeng/button';
import { SliderModule } from 'primeng/slider';
import { G2gDropdownComponent } from '../../form/g2g-dropdown/g2g-dropdown.component';
import { IVideoFilter } from '../../../models/video-filter';
import { G2gTextInputComponent } from '../../form/g2g-text-input/g2g-text-input.component';
import { CalendarModule } from 'primeng/calendar';
import { COUNTRIES } from '../../../constants/app.constants';
import { ChipsModule } from 'primeng/chips';

@Component({
  selector: 'app-filter-video-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    AutoCompleteModule,
    G2gDropdownComponent,
    G2gTextInputComponent,
    SliderModule,
    ButtonModule,
    CalendarModule,
    ChipsModule
  ],
  templateUrl: './filter-video-dialog.component.html',
  styleUrl: './filter-video-dialog.component.scss',
})
export class FilterVideoDialogComponent implements OnInit, OnDestroy {
  filterForm!: FormGroup;
  public countries = COUNTRIES;
  public filteredCountries!: ICountry[];
  private $unsubscribe = new Subject<void>();
  public categories: any[] = [];

  constructor(
    private _profileService: ProfileService,
    private ref: DynamicDialogRef,
  ) { }
  ngOnInit(): void {

    this.getAllEnums();

    this.filterForm = new FormGroup({
      title: new FormControl(null),
      category: new FormControl(null),
      // from: new FormControl(null),
      // to: new FormControl(null),
      // range: new FormControl(null),
      tags: new FormControl([])
      // skills: new FormControl(null),
    });
  }

  getAllEnums() {
    this._profileService.getAllEnums()
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((res) => {
        this.categories = res?.postCategory || [];
      });
  }

  hasError(formcontrolname: string, validation: string) {
    if (this.filterForm && formcontrolname) {
      return this.filterForm.get(formcontrolname)?.hasError(validation) && this.filterForm.get(formcontrolname)?.touched;
    } else {
      return false;
    }
  }

  filterCountry(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < (this.countries as any[]).length; i++) {
      let country = (this.countries as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  }

  closeDialog() {
    this.ref.close();
  }
  onChipAdd() {
    let tags = this.filterForm.get('tags')?.value;
    tags = tags.map((x: string) => x = x.split('#').join('').split(' ').join(''));
    this.filterForm.get('tags')?.setValue(tags);
  }

  onSubmit() {
    const filter: IVideoFilter = {
      title: this.filterForm.get('title')?.value,
      category: this.filterForm.get('category')?.value,
      // from: this.filterForm.get('from')?.value,
      // to: this.filterForm.get('to')?.value,
      tags: this.filterForm.get('tags')?.value.toString()
    }
    this.ref.close({ search: "post", filter });
  }

  resetForm() {
    this.filterForm.reset();
    this.filterForm.get('tags')?.setValue([]);
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
