<div [formGroup]="filterForm" class="filter-dialog-container">
    <span class="text-muted">
        Filter through specific criterias to find the most accurate results
    </span>
    <div class="d-flex w-100 mt-2">
        <p-chips addOnBlur="true" (onBlur)="onChipAdd()" allowDuplicate="false" field="tags" showClear="true" variant="outlined" (onAdd)="onChipAdd()" separator="," placeholder="#HashTags" formControlName="tags" >
            <ng-template let-item pTemplate="item">
                #{{ item }}
             </ng-template>
        </p-chips>
    </div>
    <div class="d-flex w-100 gap-2">
        <app-g2g-text-input class="w-100" [formname]="filterForm" type="text" formcontrolname="title"
            placeholder="Video Name"></app-g2g-text-input>
        <app-g2g-dropdown class="flex-fill" [options]="categories" displayLabel="name" value="id"
            [formname]="filterForm" formcontrolname="category" placeholder="Category"></app-g2g-dropdown>
    </div>
    <!-- <div class="d-flex w-100">
        <p-calendar [inline]="true" formControlName="range" selectionMode="range" [readonlyInput]="true" />
    </div> -->
    <div class="w-100 gap-2 d-flex align-items-center justify-content-between">
        <div>
            <p-button icon="pi pi-refresh" [text]="true" severity="secondary" (click)="resetForm()" label="Reset" />
        </div>
        <div>
            <p-button [text]="true" severity="secondary" (click)="closeDialog()" label="Cancel" />
            <p-button (click)="onSubmit()" [disabled]="!filterForm.valid" label="Apply" />
        </div>
    </div>

</div>